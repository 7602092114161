.details-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  .details-container h3 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .detail-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .detail-item .detail-label {
    color: #555;
    font-weight: bold;
    width: 120px;
  }
  .detail-item .detail-value {
    color: #777;
    flex: 1;
  }
  /* .rounded-circleimage{
    height: 50px;
    width:100px;
    object-fit: cover;
  } */

  .height-fix{
    height: 280px !important;
  }

  .height-fix img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
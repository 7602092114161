/* PrivacyPolicy.css */

.privacy-policy {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .privacy-policy h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 10px;
    }
    
    .privacy-policy h1 {
      font-size: 1.8rem;
    }
    
    .privacy-policy h2 {
      font-size: 1.3rem;
    }
    
    .privacy-policy p {
      font-size: 0.9rem;
    }
  }
  
.about_1l2 {
    top: 12%;
    right: 0;
}

.about_1l3 {
    bottom: -82%;
    right: 0;
}

#about {
    padding-bottom: 90px;
}

.events_1l2 {
    padding-top: 46%;
    transition: 0.3s;
}

.events_1l3 {
    padding-top: 46%;
    display: none;
    transition: 0.3s;
}

.events_1l:hover .events_1l3 {
    display: block;
    transition: 0.3s;
}

.events_1l:hover .events_1l2 {
    display: none;
    transition: 0.3s;
}

.events_2l {
    border-right: 1px solid #d3e9ef;
}

.book_1l .progress-bar {
    background: #F9F9F9;
    border-radius: 0;
}

.book_1l .progress {
    background-color: #c78665;
    height: 6px;
    overflow: hidden;
    border-radius: 0;
}

/*********************about_end****************/



@media screen and (max-width : 767px) {
    .about_1l2 {
        display: none;
    }

    .about_1l3 {
        display: none;
    }

    .about_1l1 img {
        width: 100%;
    }

    .about_1r {
        margin-top: 15px !important;
        text-align: center;
    }

    .about_1r p {
        text-align: left;
    }

    #about {
        padding-bottom: 30px;
    }

    .events_1lm {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .events_2l {
        margin-top: 8px;
        margin-bottom: 8px;
        border: none;
    }

    .book_1l h1 {
        text-align: center;
    }

    .book_1l {
        margin-bottom: 15px;
    }

    .book_1r h1 {
        text-align: center;
    }

    .book_1ril .form-control {
        margin-top: 10px;
    }

    .recep_1l {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .services_1l {
        margin-top: 8px;
        margin-bottom: 8px;
        text-align: center;
    }

    .services_1lir {
        margin-top: 10px;
    }
}


@media (min-width:576px) and (max-width:767px) {}

@media (min-width:768px) and (max-width:991px) {
    .about_1ril .font_60 {
        font-size: 40px;
    }

    #about {
        padding-bottom: 30px;
    }

    .events_1l2 h4 {
        font-size: 18px;
    }

    .events_1l2 h5 {
        font-size: 18px;
    }

    .services_1lil span {
        font-size: 26px !important;
    }
}

@media (min-width:992px) and (max-width:1200px) {}

@media (min-width:1400px) and (max-width:2000px) {
    .about_1l2 {
        right: 20%;
    }

    .about_1l3 {
        right: 5%;
    }
}